import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../Components/Ui/Button";
import InputComp from "../../../Components/Ui/InputComp";
import SelectCompI from "../../../Components/Ui/SelectCompI";
import { useRef, useMemo, useState } from "react";
import Country from "../../../country.json";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { LanguageName, storage } from "../../../utils/helper";
import axios from "axios";
import { toast } from "react-toastify";
import { useCreateMedRepMainMutation } from "../../../store/MedRep/MedRepApi";
import { useGetAllProfileQuery } from "../../../store/User/userApi";
import { AddImage, AddTerritoryIcon, ArrowLeft } from "../../../utils/svg";

function CreateMedRep() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [CILLoader, setCILLoader] = useState(false);
  const [addMedRep, { isLoading }] = useCreateMedRepMainMutation();
  const companyId = storage["localStorage"].get("user_details_profile")?.user
    ?.id;
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      skip: !companyId,
    }
  );
  const fileInputRef = useRef(null);
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      image: "",
      email: "",
      phoneNumber: "",
      country: "",
      state: "",
      territory: [{ label: "", id: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "territory",
  });

  const transformedArray = useMemo(() => {
    return Country.map((item) => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name,
      id: item?.id, // replace with the actual property you want as value
    }));
  }, [Country]);

  const transformedArrayState = useMemo(() => {
    return watch("country")?.id
      ? Country.find((item) => item?.id === watch("country")?.id)?.state?.map(
          (item) => ({
            label: item.name, // replace with the actual property you want as label
            value: item.name,
            id: item?.id, // replace with the actual property you want as value
          })
        )
      : [];
  }, [watch("country"), Country]);

  const transformedArrayCity = useMemo(() => {
    let state = [];
    if (watch("country")?.id) {
      state = Country.find(
        (item) => item?.id === watch("country")?.id
      )?.state.map((item) => item);
      return state
        .find((item) => item.id === watch("state")?.id)
        ?.city.map((item) => ({
          label: item.name, // replace with the actual property you want as label
          value: item.name,
          id: item?.id, // replace with the actual property you want as value
        }));
    }
  }, [watch("state"), watch("country"), Country]);

  const getFilteredOptions = (currentValue) => {
    console.log(currentValue, fields);
    return transformedArrayCity.filter(
      (city) => city.id === currentValue || !fields.includes(city.id)
    );
  };

  const handleChange = async (file) => {
    setSelectedImage(URL.createObjectURL(file));
    setCILLoader(true);
    const formData = new FormData();
    formData.append("file", file);

    // Retrieve the token from local storage
    const token = storage["localStorage"].get("user_details_profile")?.token;

    // Create headers object
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // If the token exists, add it to the headers
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/file/upload`,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // Update UI with progress
          // setProgress(progress);
          console.log(`Upload progress: ${progress}%`);
        },
      });

      console.log("response", response);
      if (response) {
        setCILLoader(false);
        toast.success(response?.data.message);
        setSelectedImage(response?.data?.data?.file_link);
      } else {
        console.error("Invalid response format. Missing file_link.");
        setCILLoader(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Upload failed:", error);
      setCILLoader(false);
    }
  };
  async function onSubmit(values) {
    if(selectedImage === "") return toast.error("Upload an Image")
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      companyId: CompanyData?.data?.company?._id,
      image: selectedImage,
      country: values.country.value,
      state: [values.state.value], // Assuming only one state is provided
      lgas: values.territory.map((t) => t.value.value),
    };
    const response = await addMedRep(payload);
    if (response?.error) {
      toast.error(response?.error?.data?.message);
    }
    if (response?.data) {
      if (!response?.data?.status) {
        return toast.error(response?.data?.message);
      }
      toast.success("Med Rep was successfully added");
      reset();
      setSelectedImage("");
    }
  }
  const handleButtonClick = () => {
    console.log(fileInputRef);
    fileInputRef.current.click();
  };

  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div
            className=" col-3 flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft />
            <span className="text-base font-[700] ml-2">Go Back</span>
          </div>
          <h1 className="text-[25px] font-[700] text-ellipsis w-[30rem] ">
            {LanguageName("CMR")}
          </h1>
        </div>
      </div>

      <div className="lg:mb-[20px] md:mb-[45px] mb-[45px] border px-4 py-6 rounded">
        <div className="row gap-5 mb-4">
          <div className="col-md-3 mb-4">
            <h3 className="font-semibold text-lg">{LanguageName("ProPic")}</h3>
            <p className="font-normal text-base text-[#667185] mt-1 mb-3">
              {LanguageName("TIWYP")}
            </p>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept={"image/*"}
              onChange={(e) => handleChange(e.target.files[0])}
            />
            <div>
              <div
                className="flex justify-center items-center mt-4 w-40 py-[1.5px] rounded border-[2px] border-[#8652CE] cursor-pointer"
                onClick={handleButtonClick}
              >
                <AddImage />
                <ButtonComp
                  text={LanguageName("UpPho")}
                  btnClassName={"text-center text-[#8652CE] ml-2 font-semibold"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="border-2 flex justify-center items-center w-36 h-36 rounded-full overflow-hidden">
              <img
                src={selectedImage || "/HCPList/profile.svg"}
                className="w-full h-full object-cover"
                alt="Profile"
              />
            </div>
          </div>
        </div>
        <div className="border-t"></div>
        <div className="row gap-5 mt-4">
          <div className="col-md-3 mb-4">
            <div className="">
              <h3 className="font-semibold text-lg">{LanguageName("PI")}</h3>
              <p className="font-normal text-base text-[#667185] mt-1 mb-3">
                {LanguageName("IYDH")}
              </p>
              <ButtonComp
                onClick={handleSubmit(onSubmit)}
                text={LanguageName("CMR")}
                loader={isLoading}
                btnClassName={
                  "bg-[#8652CE] text-white rounded px-3 py-1 font-semibold text-base"
                }
              />
            </div>
          </div>
          <div className="col-md-8 grow-[1]">
            <form>
              <div className="flex flex-col md:flex-row w-full gap-4 mb-4">
                <div className="w-full">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: LanguageName("FiR") }}
                    render={({ field }) => (
                      <InputComp
                        {...field}
                        label={LanguageName("FN")}
                        labelClassName="text-sm mb-0 mb-1"
                        wrapperClassName="w-full"
                      />
                    )}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-xs">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: LanguageName("LiR") }}
                    render={({ field }) => (
                      <InputComp
                        {...field}
                        label={LanguageName("LN")}
                        labelClassName="text-sm mb-0 mb-1"
                        wrapperClassName="w-full"
                      />
                    )}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-xs">
                      {errors.lastName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: LanguageName("EAiR") }}
                  render={({ field }) => (
                    <InputComp
                      {...field}
                      label={LanguageName("EA")}
                      labelClassName="text-sm mb-0 mb-1"
                      wrapperClassName="w-full"
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">{errors.email.message}</p>
                )}
              </div>

              <div className="flex flex-col md:flex-row w-full gap-4 mb-4">
                <div className="w-full">
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: LanguageName("CiR") }}
                    render={({ field }) => (
                      <SelectCompI
                        {...field}
                        label={LanguageName("COTY")}
                        labelClassName="text-sm mb-0 mb-1"
                        wrapperClassName="w-full"
                        options={transformedArray}
                        placeholder={LanguageName("SeCOTY")}
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-red-500 text-xs">
                      {errors.country.message}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: LanguageName("SiR") }}
                    render={({ field }) => (
                      <SelectCompI
                        {...field}
                        label={LanguageName("ST")}
                        labelClassName="text-sm mb-0 mb-1"
                        wrapperClassName="w-full"
                        options={transformedArrayState}
                        placeholder={LanguageName("SeST")}
                      />
                    )}
                  />
                  {errors.state && (
                    <p className="text-red-500 text-xs">
                      {errors.state.message}
                    </p>
                  )}
                </div>
                {/* <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <SelectCompI
                      {...field}
                      label="State"
                      labelClassName="text-sm mb-0 mb-1"
                      wrapperClassName="w-full"
                      options={transformedArrayState}
                    />
                  )}
                /> */}
              </div>
              <div className="mt-4">
                <label htmlFor="" className="font-medium text-sm">
                  {LanguageName("TRY")}
                </label>
                {fields.map((item, index) => (
                  <div key={item.id}>
                    <Controller
                      name={`territory[${index}].value`}
                      control={control}
                      rules={{ required: LanguageName('TiR') }}
                      render={({ field }) => (
                        <SelectCompI
                          {...field}
                          // label="Territory"
                          labelClassName="text-sm mb-0 mb-1"
                          wrapperClassName="w-full"
                          containerClassName={
                            index === fields.length - 1 ? "mb-2" : "mb-6"
                          }
                          options={
                            transformedArrayCity &&
                            getFilteredOptions(item.id)
                          }
                        />
                      )}
                    />
                    {/* {index > 0 && (
                      <button
                        type="button"
                        className="font-semibold"
                        onClick={() => remove(index)}
                      >
                        -
                      </button>
                    )} */}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => append({ label: "", id: "" })}
                  className="text-sm text-[#8652CE] font-semibold flex items-center"
                >
                  <span className="mr-1">
                    <AddTerritoryIcon />
                  </span>
                  <span>{LanguageName("ANT")}</span>
                </button>
              </div>
              <div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: "Phone number is required" }}
                  render={({ field }) => (
                    <InputComp
                      {...field}
                      label={LanguageName("PNB")}
                      labelClassName="text-sm mb-0 mb-1"
                      wrapperClassName="w-full mt-4"
                      containerClassName=""
                      type="number"
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMedRep;
